import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import './scss/style.scss'
// import { w3cwebsocket as W3CWebSocket } from "websocket"
// import { connect } from 'src/reducers/wswaReducer';
// import { useDispatch } from 'react-redux';
// 

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

// function getToken() {
//  var token = localStorage.getItem("token")
//   return token 
// }



class App extends Component {

 

  componentDidMount() {
    console.log("testing app");
    // console.log(document.location.hostname);
    if(!!localStorage["token"] === true){

    } else {
      window.location.href = `#login`;
    }
 
    var hostname = document.location.hostname

    if (!!localStorage["api_url"] === true) {
      console.log("Существует")
    
      if (hostname === 'mvpkraski.ark-server.ru') {
        localStorage.setItem('api_url', "/api")
      }
      if (hostname === '192.168.1.13') {
        localStorage.setItem('api_url', "http://mvpkraski.ark-server.ru/api")
      }

      if (hostname === '192.168.1.17') {
        localStorage.setItem('api_url', "/api")
      }

      if (hostname === 'crm.ark-server.ru') {
        localStorage.setItem('api_url', "http://mvpkraski.ark-server.ru/api")
      }

      if (hostname === 'admintravel.ark-server.ru') {
        localStorage.setItem('api_url', "http://mvpkraski.ark-server.ru/api")
      }

      if (hostname === 'devadmin.kkg54.ru') {
        localStorage.setItem('api_url', "/api")
      }

      if (hostname === 'gen.w435.ru') {
        localStorage.setItem('api_url', "/api")
      }
    } else {
      console.log("Не существует")
      // var hostname = document.location.hostname

      if (hostname === '192.168.1.17') {
        localStorage.setItem('api_url', "http://192.168.1.17:8093/api")
      }

      if (hostname === '192.168.1.44') {
        localStorage.setItem('api_url', "http://apia.ark-server.ru")
      }

      if (hostname === 'crmtravel.ark-server.ru') {
        localStorage.setItem('api_url', "http://apia.ark-server.ru")
      }

      if (hostname === 'crm.ark-server.ru') {
        localStorage.setItem('api_url', "http://mvpkraski.ark-server.ru/api")
      }

      if (hostname === 'ark-server.ru') {
        localStorage.setItem('api_url', "http://mvpkraski.ark-server.ru")
      }

      if (hostname === 'mvpkraski.ark-server.ru') {
        localStorage.setItem('api_url', "/api")
      }

      if (hostname === '192.168.1.44') {
        localStorage.setItem('api_url', "http://apia.ark-server.ru")
      }

      if (hostname === '192.168.1.13') {
        localStorage.setItem('api_url', "http://mvpkraski.ark-server.ru/api")
      }

      if (hostname === 'admintravel.ark-server.ru') {
        localStorage.setItem('api_url', "http://mvpkraski.ark-server.ru/api")
      }

      if (hostname === 'devadmin.kkg54.ru') {
        localStorage.setItem('api_url', "/api")
      }

      if (hostname === 'gen.w435.ru') {
        localStorage.setItem('api_url', "http://genapi.w435.ru/api")
      }

    }

    // connect(new W3CWebSocket("ws://localhost:5001"))
    // dispa
  //  useDispatch(connect(new W3CWebSocket("ws://localhost:5000")))
  // dispatch

  }


  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    )
  }
}

export default App
