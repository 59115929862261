
import { w3cwebsocket as W3CWebSocket } from "websocket"
const CONNECT = "CONNECT"
const SET_STATUS = "SET_STATUS"


// new W3CWebSocket("ws://localhost:5000")
const defaultState = {
    connecting:false,
    ws:new W3CWebSocket("ws://ark-server.ru:5000"),
    wspdf:new W3CWebSocket("ws://localhost:5001"),
    status:false,
}

export default function wswaReducer(state = defaultState  , action){
    switch (action.type){
        case CONNECT:
            return {
              ...state,
              ws:action.payload,
            }

            case SET_STATUS:
                return {
                    ...state,
                    status:action.payload,
                  } 
       
   default:
       return state
        
    }
}

export const connect = (ws) => ({type:CONNECT , payload:ws})
export const setStatus = (status) => ({type:SET_STATUS , payload:status})