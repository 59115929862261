const SET_SIDEBAR = "SET_SIDEBAR"
const SET_UNFORLDABLE = "SET_UNFORLDABLE"


const defaultState = {
  sidebarShow: true,
  sidebarUnfoldable:true,
  
}

export default function sidebarReducer(state = defaultState  , action){
  switch (action.type){
      case SET_SIDEBAR:
        // console.log("testing")
          return {
            ...state,
            sidebarShow:action.payload,
          }
      case SET_UNFORLDABLE:
          return {
            ...state,
            sidebarUnfoldable:action.payload,
          }
     
 default:
     return state
      
  }
}

export const setSidebar = (sidebarShow) => ({type:SET_SIDEBAR , payload:sidebarShow})
export const setUnforldable = (sidebarUnfoldable) => ({type:SET_UNFORLDABLE , payload:sidebarUnfoldable})

// const sidebarReducer = (state = initialState, { type, ...rest }) => {
//   switch (type) {
//     case 'set':
//       return { ...state, ...rest }
//     default:
//       return state
//   }
// }

// const store = createStore(changeState)
// export default store
