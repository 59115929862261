import { combineReducers } from "redux";
import { createStore , applyMiddleware } from "redux";
import reposReducer from "./reposReducer";
import { composeWithDevTools } from '@redux-devtools/extension';
import thunk from "redux-thunk";
import sidebarReducer from "./sidebarReducer";
import wswaReducer from "./wswaReducer"

const rootReducer = combineReducers({
    repos:reposReducer,
    sidebar:sidebarReducer,
    wswa:wswaReducer,
   
})

export const store = createStore(rootReducer , composeWithDevTools(applyMiddleware(thunk)) )